import firebase from 'firebase/app'
import 'firebase/firestore'

firebase.initializeApp({
  apiKey: "AIzaSyAJLhGb_rQv5gbKvfcszApG1wQGpGP7vas",
  authDomain: "unstuck-7471b.firebaseapp.com",
  databaseURL: "https://unstuck-7471b.firebaseio.com",
  projectId: "unstuck-7471b",
  storageBucket: "unstuck-7471b.appspot.com",
  messagingSenderId: "217520981349",
  appId: "1:217520981349:web:59dbe71dba579076bf0d8c",
  measurementId: "G-XDYBXEJXEM"
})

export const db = firebase.firestore()
