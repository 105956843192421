import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPrompts } from '../reducers/prompts'
import { RootState } from '../reducers'

interface PromptProps {
  shouldHide: boolean
}

function Prompt({ shouldHide }: PromptProps) {
  const dispatch = useDispatch()
  const { selectedItem, isLoading, error } = useSelector(
    (state: RootState) => state.prompts
  )

  let prompt = "TBD after freeze"
  if (!shouldHide) {
    if (isLoading || error || !selectedItem) {
      prompt = "..."
    } else {
      prompt = selectedItem
    }
  }

  useEffect(() => {
    dispatch(fetchPrompts())
  }, [dispatch])

  return (
    <span className="Prompt">Theme: {prompt}</span>
  )
}

export default Prompt
