import React from 'react'
import './App.css'
import Editor from '../Editor'
import { useDispatch, useSelector } from 'react-redux'
import { addPath, resetDoodle, switchMode } from '../../reducers/doodles'
import { selectNewPrompt } from '../../reducers/prompts'
import { RootState } from '../../reducers'
import Link from '../Link'
import Prompt from '../Prompt'
import { Path, Point } from '../../types'

function App() {
  const dispatch = useDispatch()
  const dispatchAddPath = (path: Path) => dispatch(addPath(path))

  const { base, addition, mode } = useSelector(
    (state: RootState) => state.doodles
  )
  let combinedPaths = base.concat(addition)

  return (
    <div className="App container">
      <header>
        <h1>Get Unstuck</h1>
        <h5>A tool for removing mental blocks</h5>
      </header>
      <div className="row">
        <div className="three columns">&nbsp;</div>
        <div className="six columns">
          <Prompt shouldHide={mode === 'base'} />
        </div>
        <div className="three columns">&nbsp;</div>
      </div>
      <div className="row">
        <div className="three columns">
          <h3>Instructions</h3>
          <ol className="instructions">
            <li>Make an abstract squiggle, doodle, whatever.</li>
            <li>Freeze that part of the drawing to reveal a theme.</li>
            <li>Add to your drawing to make it fit the theme.</li>
          </ol>
        </div>
        <div className="six columns">
          <Editor
            paths={combinedPaths}
            addPath={dispatchAddPath}
            width={800}
            height={494}
          />
        </div>
        <div className="three columns">&nbsp;</div>
      </div>
      <div className="row">
        <div className="twelve columns">
          <Link
            type={(mode === 'base') ? "primary" : "secondary"}
            onClick={() => {
              if (mode === 'base') {
                dispatch(selectNewPrompt())
              }
              dispatch(switchMode())
            }}
          >
            {(mode === 'base') ? "Freeze and show theme" : "Start another drawing"}
          </Link>
          &nbsp;
          <Link onClick={() => dispatch(resetDoodle())}>
            reset
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;
