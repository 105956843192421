import { createSlice, PayloadAction } from 'redux-starter-kit'
import { Action } from 'redux-starter-kit'
import { ThunkAction } from 'redux-thunk'
import { db } from '../firebase'
import { RootState } from '.'

export interface PromptsState {
  items: Array<string>,
  selectedItem: string | null,
  isLoading: boolean,
  error: string | null
}

let initialState: PromptsState = {
  items: [],
  selectedItem: null,
  isLoading: false,
  error: null
}

function getRandomItem(items: Array<string>): string {
  return items[Math.floor(Math.random() * items.length)]
}

const promptsSlice = createSlice({
  slice: 'prompts',
  initialState,
  reducers: {
    getPromptsStarted(state: PromptsState) {
      state.isLoading = true
    },
    getPromptsSucceeded(state: PromptsState, { payload }: PayloadAction<Array<string>>) {
      state.items = payload
      state.selectedItem = getRandomItem(state.items)
      state.isLoading = false
    },
    getPromptsFailed(state: PromptsState, { payload }: PayloadAction<string>) {
      state.error = payload
      state.isLoading = false
    },
    selectNewPrompt(state: PromptsState) {
      // TODO: Avoid repeating items
      state.selectedItem = getRandomItem(state.items)
    }
  }
})

export const {
  getPromptsStarted,
  getPromptsSucceeded,
  getPromptsFailed,
  selectNewPrompt
} = promptsSlice.actions

export default promptsSlice.reducer

// TODO: move definition to store if this is needed elsewhere
// as in https://github.com/reduxjs/rsk-github-issues-example/blob/9d1246a4d89f21da1f0e5377f040bc766e1fc0fd/src/app/store.ts
type AppThunk = ThunkAction<void, RootState, null, Action<string>>

// Modeled on https://github.com/reduxjs/rsk-github-issues-example/blob/9d1246a4d89f21da1f0e5377f040bc766e1fc0fd/src/features/issuesList/issuesSlice.ts
export const fetchPrompts = (): AppThunk => async dispatch => {
  try {
    dispatch(getPromptsStarted())
    const snapshot = await db.collection('prompts').get()
    const prompts: Array<string> = []
    snapshot.forEach( doc => {
      prompts.push(doc.data().text)
    })
    dispatch(getPromptsSucceeded(prompts))
  } catch (err) {
    dispatch(getPromptsFailed(err.toString()))
  }
}
