import React, { Component, SyntheticEvent } from 'react'

import { Point, Path } from '../types'

interface EditorProps {
  paths: Array<Path>
  addPath: (path: Path) => void
  height?: number
  width?: number
}

class Editor extends Component<EditorProps, {}> {
  private canvasRef = React.createRef<HTMLCanvasElement>()
  private renderingContext: CanvasRenderingContext2D | null = null
  private isDrawing: boolean = false
  private points: Array<Point> = []

  getPoint(event: React.MouseEvent) {
    const element = this.canvasRef.current
    let point: Point | null = null
    if (element) {
      const rect = element.getBoundingClientRect()
      let x = event.pageX - (rect.left + window.scrollX)
      let y = event.pageY - (rect.top + window.scrollY)
      point = [2 * x, 2 * y]
    }
    return point
  }

  // TODO: figure out why MouseEvent type doesn't work
  handleMouseDown(event: React.MouseEvent) {
    this.isDrawing = true
    this.points = []
    const point = this.getPoint(event)
    if (point) {
      let [x, y] = point
      this.points.push(point)
      if (this.renderingContext) {
        this.renderingContext.beginPath()
        this.renderingContext.moveTo(x, y)
      }
    }
  }

  handleMouseMove(event: React.MouseEvent) {
    if (this.isDrawing) {
      const point = this.getPoint(event)
      if (point) {
        let [x, y] = point
        this.points.push(point)
        if (this.renderingContext) {
          this.renderingContext.lineTo(x, y)
          this.renderingContext.stroke()
        }
      }
    }
  }

  handleCompletePath() {
    // No need to finish drawing directly on canvas because the action
    // will generate an update that triggers drawCanvas
    this.props.addPath(this.points)
    this.isDrawing = false
    this.points = []
  }

  handleMouseUp() {
    this.handleCompletePath()
  }

  handleMouseLeave() {
    this.handleCompletePath()
  }

  drawPath(path: Path, context: CanvasRenderingContext2D) {
    if (path.length < 2) {
      return
    }
    // TODO: handle single points

    context.beginPath()
    path.forEach((point: Point, i) => {
      let [x, y] = point
      if (i === 0) {
        context.moveTo(x, y)
      } else {
        context.lineTo(x, y)
        if (i === path.length - 1) {
          context.stroke()
        }
      }
    })
  }

  drawCanvas() {
    const canvas: HTMLCanvasElement | null = this.canvasRef.current
    if (canvas) {
      if (!this.renderingContext) {
        this.renderingContext = canvas.getContext('2d')
      }
      let context = this.renderingContext
      if (context) {
        const { paths } = this.props
        let actuallyAContext: CanvasRenderingContext2D = context

        context.clearRect(0, 0, canvas.width, canvas.height)
        paths.forEach(path => this.drawPath(path, actuallyAContext))
      }
    }
  }

  componentDidMount() {
    this.drawCanvas()
  }

  componentDidUpdate() {
    this.drawCanvas()
  }

  render() {
    let { paths, width, height } = this.props
    // Note that width/height specify canvas coordinates, not size on the screen
    return (
      <canvas
        ref={this.canvasRef}
        width={width}
        height={height}
        onMouseDown={this.handleMouseDown.bind(this)}
        onMouseMove={this.handleMouseMove.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
        onMouseUp={this.handleMouseUp.bind(this)}
      />
    )
  }
}

export default Editor
