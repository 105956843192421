import React from 'react'

interface LinkProps {
  onClick: () => void
  children: any
  disabled?: boolean
  type?: 'primary' | 'secondary'
}

const Link = ({disabled, children, onClick, type}: LinkProps) => (
  <button
  onClick={onClick}
  disabled={disabled}
  className={type === 'primary' ? "button-primary" : ""}
  >
    {children}
  </button>
)

export default Link
