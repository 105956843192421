// see https://redux-starter-kit.js.org/tutorials/advanced-tutorial

import { combineReducers } from 'redux-starter-kit'
import doodlesReducer from './doodles'
import promptsReducer from './prompts'

const rootReducer = combineReducers({
  doodles: doodlesReducer,
  prompts: promptsReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
