import { createSlice, PayloadAction } from 'redux-starter-kit'

import { Point, Path } from '../types'

export interface DoodlesState {
  base: Array<Path>
  addition: Array<Path>
  mode: 'base' | 'add'
}

let initialState: DoodlesState = {
  // base: [[[1,1], [100,100]]],
  base: [],
  addition: [],
  mode: 'base'
}

function getArray(state: DoodlesState): Array<Path> {
// getArray(state: DoodlesState): Array<Path> {
  return (state.mode === 'base') ? state.base : state.addition
}

const doodlesSlice = createSlice({
  slice: 'doodles',
  initialState,
  reducers: {
    // addPoint(state, action: PayloadAction<Point>) {
    //   const array: Array<Path> = (state.mode === 'base') ? state.base : state.addition
    //   array[array.length - 1].push(action.payload)
    // },
    // closePath(state) {
    //   const array: Array<Path> = (state.mode === 'base') ? state.base : state.addition
    //   array.push([])
    // },
    addPath(state, action: PayloadAction<Path>) {
      const array = getArray(state)
      array.push(action.payload)
    },
    resetDoodle(state) {
      if (state.mode === 'base') {
        state.base = []
      } else {
        state.addition = []
      }
    },
    switchMode(state) {
      if (state.mode === 'base') {
        state.mode = 'add'
      } else {
        state.mode = 'base'
        state.base = []
        state.addition = []
      }
    }
  }
})

export const {
  addPath,
  resetDoodle,
  switchMode
} = doodlesSlice.actions

export default doodlesSlice.reducer
